import React, { FC, useContext } from "react";
import { Box, Flex, Heading, Text as ChakraText, useMediaQuery } from "@chakra-ui/react";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { sc, SerializedItem, useSitecoreContext } from "~/foundation/Jss";
import { KeyIRNumbersProps, KeyIRNumbersRow, KeyIRNumbersRowItem } from "../generated-types";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { ExtendedBox } from "~/foundation/Components/ExtendedBox";
import { useInView } from "react-intersection-observer";
import { themable } from "~/foundation/Theme";
import { RichTextStylingSmall } from "~/foundation/Components/RichText/styles";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { breakpoints } from "~/foundation/Theme/variables/breakpoints";

const KeyIRNumbers: FC<KeyIRNumbersProps> = ({ fields, rendering: { children } }) => {
	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);
	const [isMdBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.md})`, {
		ssr: true,
		fallback: false, // return false on the server, and re-evaluate on the client side
	})

	const richTexts = [
		fields?.explanatoryTextColumn1?.value,
		fields?.explanatoryTextColumn2?.value,
		fields?.explanatoryTextColumn3?.value,
	].filter(Boolean);
	const columnCount = sitecoreContext.pageEditing ? 3 : richTexts.length;

	return (
		<Ribbon>
			<ContentWrapper py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}>
				<Box textAlign="center" maxW="782" mx="auto">
					{(fields?.heading?.value && !sitecoreContext.pageEditing) &&
						<Heading
							as="h2"
							size="md"
							mb={["3", null, "4"]}
							dangerouslySetInnerHTML={{ __html: fields.heading.value }} />
					}
					{sitecoreContext.pageEditing &&
						<Heading as="h2" size="md" mb={["3", null, "4"]}>
							<sc.Text field={fields?.heading}/>
						</Heading>
					}
					{(fields?.description?.value || sitecoreContext.pageEditing) &&
						<ChakraText size="body" mt={["3", null, "4"]} mb={["8", null, "12"]}>
							<sc.Text field={fields?.description}/>
						</ChakraText>
					}
				</Box>
				<Box position="relative">
					{children?.map((row: SerializedItem<KeyIRNumbersRow>, rowIndex) => {
						const { ref, inView } = useInView({
							threshold: 0,
							//triggerOnce: true,
						});

						return (
							<React.Fragment key={row.id}>
								<Box />
								{(row.fields?.title?.value || sitecoreContext.pageEditing) &&
									<ChakraText variant="overline" mt={["6", null, "8"]} textAlign="center">
										<sc.Text field={row.fields?.title}/>
									</ChakraText>
								}
								<Box
									ref={ref}
									borderRadius="lg"
									transform="translateZ(0)" //to fix safari border-radius
									overflow="hidden"
									display="flex"
									w="100%"
									flexDirection="row"
									flexWrap={["wrap", null, "nowrap"]}
									alignItems="center"
									gap="1px"
									mt="4"
									mb={rowIndex !== (children?.length - 1) ? ["12", null, null, "14"] : "0"}
								>
									{row.children.map((item: SerializedItem<KeyIRNumbersRowItem>, index) => {
										//custom stagger effect
										const boxStartY = 20 * index + 100;
										const boxStartX = 20 * index + 100;

										const keyIRBox = {
											initial: isMdBreakpointOrAbove ? { x: 0, y: `${boxStartY}%` } : { x: `-${boxStartX}%`, y: 0 },
											visible: {
												x: 0,
												y: 0,
											},
										}

										const keyIRText = {
											initial: {
												x: 5,
												y: 60
											},
											visible: {
												x: 0,
												y: 0,
											},
										}

										const textDelay = .3 + (index * .1);

										const transition = {
											keyIRBox: {
												ease: [.35,1,.45,1],
												duration: .7,
												//delay: .2
											},
											keyIRText: {
												ease: [.35,1,.45,1],
												duration: .7,
												delay: textDelay
											}
										}

										return (
											<MotionBox
												key={item.id}
												flexBasis={["100%", null, row.children.length === 3 ? "33.3333%" : "50%"]}
												flexShrink="1"
												flexGrow="1"
												textAlign="center"
												variants={keyIRBox}
												initial="initial"
												animate={inView ? "visible" : "initial"}
												transition={transition.keyIRBox}
											>
												<ExtendedBox
													bg={rowIndex === 0 ? "primary.darkBlue" : "primary.white"}
													color={rowIndex === 0 ? "primary.white" : "primary.darkBlue"}
													py={["4", null, "8", null, "12"]}
													px={["4", null, "8"]}
												>
													{(item.fields?.currencyTop?.value || item.fields?.numberTop?.value || item.fields?.numberTypeTop?.value || sitecoreContext.pageEditing) &&
														<ExtendedBox overflow="hidden">
															<MotionBox
																variants={keyIRText}
																initial="initial"
																animate={inView ? "visible" : "initial"}
																transition={transition.keyIRText}
															>
																<ChakraText variant="keyIrNumber">
																	{sitecoreContext.pageEditing ?
																		<>
																			<Box as="span" pe="2" fontSize={!item.fields?.currencyTop?.value ? "1rem" : "inherit"}>
																				<sc.Text field={item.fields?.currencyTop}/>
																			</Box>
																			<Box as="span" fontSize={!item.fields?.numberTop?.value ? "1rem" : "inherit"}>
																				<sc.Text field={item.fields?.numberTop}/>
																			</Box>
																			<Box as="span" fontSize={!item.fields?.numberTypeTop?.value ? "1rem" : "inherit"}>
																				<sc.Text field={item.fields?.numberTypeTop}/>
																			</Box>
																		</>
																		:
																		<>
																			{item.fields?.currencyTop?.value &&
																				<Box as="span" pe="2" dangerouslySetInnerHTML={{ __html: item.fields.currencyTop.value }}/>
																			}
																			{item.fields?.numberTop?.value &&
																				<Box as="span">
																					<sc.Text field={item.fields?.numberTop}/>
																				</Box>
																			}
																			{item.fields?.numberTypeTop?.value &&
																				<Box as="span" dangerouslySetInnerHTML={{ __html: item.fields.numberTypeTop.value }}/>
																			}
																		</>
																	}
																</ChakraText>
															</MotionBox>
														</ExtendedBox>
													}
													{(item.fields?.currencyBottom?.value || item.fields?.numberBottom?.value || item.fields?.numberTypeBottom?.value || sitecoreContext.pageEditing) &&
														<ExtendedBox color="primary.aramcoBlue" overflow="hidden">
															<MotionBox
																variants={keyIRText}
																initial="initial"
																animate={inView ? "visible" : "initial"}
																transition={transition.keyIRText}
															>
																<ChakraText variant="keyIrNumber">
																	{sitecoreContext.pageEditing ?
																		<>
																			<Box as="span" pe="2" fontSize={!item.fields?.currencyBottom?.value ? "1rem" : "inherit"}>
																				<sc.Text field={item.fields?.currencyBottom}/>
																			</Box>
																			<Box as="span" fontSize={!item.fields?.numberBottom?.value ? "1rem" : "inherit"}>
																				<sc.Text field={item.fields?.numberBottom}/>
																			</Box>
																			<Box as="span" fontSize={!item.fields?.numberTypeBottom?.value ? "1rem" : "inherit"}>
																				<sc.Text field={item.fields?.numberTypeBottom}/>
																			</Box>
																		</>
																		:
																		<>
																			{item.fields?.currencyBottom?.value &&
																				<Box as="span" pe="2" dangerouslySetInnerHTML={{ __html: item.fields.currencyBottom.value }}/>
																			}
																			{item.fields?.numberBottom?.value &&
																				<Box as="span">
																					<sc.Text field={item.fields?.numberBottom}/>
																				</Box>
																			}
																			{item.fields?.numberTypeBottom?.value &&
																				<Box as="span" dangerouslySetInnerHTML={{ __html: item.fields.numberTypeBottom.value }}/>
																			}
																		</>
																	}
																</ChakraText>
															</MotionBox>
														</ExtendedBox>
													}
													{(item.fields?.text?.value || sitecoreContext.pageEditing) &&
														<ExtendedBox mt={["2", null, null, "3"]}>
															<ChakraText size="body">
																<sc.Text field={item.fields?.text} />
															</ChakraText>
														</ExtendedBox>
													}
												</ExtendedBox>
											</MotionBox>
										)
									})}
								</Box>
							</React.Fragment>
						)
					})}
				</Box>
				{(columnCount > 0) &&
					<Flex
						flexDirection="row"
						gap={["7", null, "3rem"]}
						flexWrap="wrap"
						justifyContent={["none", null, "center"]}
						pt={["12", null, "16"]}
						sx={{
							"> div": {
								flexBasis: ["100%", null, columnCount > 1 ? `calc(50% - 1.5rem)` : "60%", columnCount > 1 ? `calc(100% / ${columnCount} - 2rem)` : "60%"],
							}
						}}
					>
						{(fields?.explanatoryTextColumn1?.value || sitecoreContext.pageEditing) &&
							<Box
								{...RichTextStylingSmall(theme, sitecoreContext.custom.settings.isRtl)}>
								<sc.RichText field={fields?.explanatoryTextColumn1} enableWordExplainer />
							</Box>
						}
						{(fields?.explanatoryTextColumn2?.value || sitecoreContext.pageEditing) &&
							<Box
								{...RichTextStylingSmall(theme, sitecoreContext.custom.settings.isRtl)}>
								<sc.RichText field={fields?.explanatoryTextColumn2} enableWordExplainer />
							</Box>
						}
						{(fields?.explanatoryTextColumn3?.value || sitecoreContext.pageEditing) &&
							<Box
								{...RichTextStylingSmall(theme, sitecoreContext.custom.settings.isRtl)}>
								<sc.RichText field={fields?.explanatoryTextColumn3} enableWordExplainer />
							</Box>
						}
					</Flex>
				}
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable()(KeyIRNumbers);